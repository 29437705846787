function initializeForm() {
  const $typeField = $('#payment_method_type');
  const $paypalFields = $('.paypal-fields');
  const $venmoFields = $('.venmo-fields');
  const $bankFields = $('.bank-fields');

  function updateFieldsVisibility() {
      console.log('updateFieldsVisibility called');
      const selectedType = $typeField.val();
      console.log('Selected Type:', selectedType);

      switch (selectedType) {
          case 'Paypal':
              $paypalFields.removeClass('d-none').find('input').attr('required', true);
              $venmoFields.addClass('d-none').find('input').removeAttr('required');
              $bankFields.addClass('d-none').find('input').removeAttr('required');
              break;
          case 'Venmo':
              $venmoFields.removeClass('d-none').find('input').attr('required', true);
              $paypalFields.addClass('d-none').find('input').removeAttr('required');
              $bankFields.addClass('d-none').find('input').removeAttr('required');
              break;
          case 'BankAccount':
              $bankFields.removeClass('d-none').find('input').attr('required', true);
              $paypalFields.addClass('d-none').find('input').removeAttr('required');
              $venmoFields.addClass('d-none').find('input').removeAttr('required');
              break;
          default:
              $paypalFields.addClass('d-none').find('input').removeAttr('required');
              $venmoFields.addClass('d-none').find('input').removeAttr('required');
              $bankFields.addClass('d-none').find('input').removeAttr('required');
              break;
      }
  }

  $typeField.on('change', updateFieldsVisibility);

  updateFieldsVisibility();
}

document.addEventListener('turbo:load', function() {
  console.log('Inside turbo:load');
  initializeForm();
});

document.addEventListener('turbo:frame-load', function(event) {
  const frameId = event.target.id;
  console.log('Inside turbo:frame-load', frameId);
  if (frameId === 'payment_form') {
    initializeForm();
  }
});