// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./modernize/vendor.min"
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;
import * as OwlCarousel from "owl.carousel"
window.OwlCarousel = OwlCarousel;
import "aos"
import * as AOS from "aos";
window.AOS = AOS;
import "./modernize/simplebar/dist/simplebar.min"
import "./modernize/theme/app.init"
import "./modernize/theme/theme"
import "./modernize/theme/app.min"
import "./payment_methods_form"
import "iconify-icon"
import "./controllers"

// document.addEventListener("turbo:load", function () {
//   console.log("initializing select2");
//   $('.select2').select2();
// });

// document.addEventListener("turbo:frame-load", function () {
//   console.log("initializing select2");
//   $('.select2').select2();
// });

// document.addEventListener('turbo:submit-end', (event) => {
//   const { detail } = event;
//   const { success } = detail;

//   // Check if the form submission was not successful
//   if (!success) {
//     // Handle the rendering of the form with validation errors
//     console.log('Form submission failed due to validation errors.');
//     console.log("initializing select2");
//     $('.select2').select2();
//   }
// });
