import { Controller } from "@hotwired/stimulus"
import select2 from "select2"
window.select2 = select2;

export default class extends Controller {
  connect() {
    console.log("connected");
    $('.select2').select2();
  }
  
  disconnect() {
    console.log("disconnected");
    $('.select2').select2('destroy');
  }
}
