// Merge default and provided settings
var settings = Object.assign({}, userSettings);
var isSidebar = document.getElementsByClassName("customizer");

var AdminSettings = {
  // Settings INIT
  AdminSettingsInit: function () {
    AdminSettings.ManageThemeLayout();
    AdminSettings.ManageSidebarType();
    AdminSettings.ManageBoxedLayout();
    AdminSettings.ManageDirectionLayout();
    AdminSettings.ManageDarkThemeLayout();
    AdminSettings.ManageColorThemeLayout();
    AdminSettings.ManageCardLayout();
  },

  //****************************
  // Vertical / Horizontal Layout
  //****************************
  ManageThemeLayout: function () {
    switch (settings.Layout) {
      case "horizontal":
        // document.getElementById("horizontal-layout").checked = true;
        document.documentElement.setAttribute("data-layout", "horizontal");
        break;
      case "vertical":
        // document.getElementById("vertical-layout").checked = true;
        document.documentElement.setAttribute("data-layout", "vertical");
        break;
      default:
    }
  },

  //****************************
  // Full / Minisidebar type
  //****************************
  ManageSidebarType: function () {
    switch (settings.SidebarType) {
      //****************************
      // If the sidebar type has full
      //****************************
      case "full":
        // document.querySelector("#full-sidebar").checked = true;
        document.body.setAttribute("data-sidebartype", "full");
        //****************************
        /* This is for the mini-sidebar if width is less then 1170*/
        //****************************
        var setsidebartype = function () {
          var width =
            window.innerWidth > 0 ? window.innerWidth : this.screen.width;
          if (width < 1300) {
            document.body.setAttribute("data-sidebartype", "mini-sidebar");
          } else {
            document.body.setAttribute("data-sidebartype", "full");
          }
        };
        window.addEventListener("turbo:load", setsidebartype);
        window.addEventListener("resize", setsidebartype);
        break;

      //****************************
      // If the sidebar type has mini-sidebar
      //****************************
      case "mini-sidebar":
        // document.querySelector("#mini-sidebar").checked = true;
        document.body.setAttribute("data-sidebartype", "mini-sidebar");
        break;

      default:
    }
  },

  //****************************
  // Layout Boxed or Full
  //****************************
  ManageBoxedLayout: function () {
    // document.getElementById("boxed-layout").checked = true;
    switch (settings.BoxedLayout) {
      case true:
        document.documentElement.setAttribute("data-boxed-layout", "boxed");
        // document.getElementById("boxed-layout").checked = true;
        break;
      case false:
        document.documentElement.setAttribute("data-boxed-layout", "full");
        // document.getElementById("full-layout").checked = true;
        break;
      default:
    }
  },
  //****************************
  // Direction Type
  //****************************
  ManageDirectionLayout: function () {
    switch (settings.Direction) {
      case "ltr":
        // document.getElementById("ltr-layout").checked = true;
        document.documentElement.setAttribute("dir", "ltr");
        var offcanvasStart = document.querySelector(".offcanvas-start");
        if (offcanvasStart) {
          offcanvasStart.classList.toggle("offcanvas-end");
          offcanvasStart.classList.remove("offcanvas-start");
        }
        break;
      case "rtl":
        document.documentElement.setAttribute("dir", "rtl");
        var offcanvasEnd = document.querySelector(".offcanvas-end");
        if (offcanvasEnd) {
          offcanvasEnd.classList.toggle("offcanvas-start");
          offcanvasEnd.classList.remove("offcanvas-end");
        }
        // document.getElementById("rtl-layout").checked = true;
        break;
      default:
    }
  },
  //****************************
  // Card Type
  //****************************
  ManageCardLayout: function () {
    // document.getElementById("card-without-border").checked = true;
    switch (settings.cardBorder) {
      case true:
        document.documentElement.setAttribute("data-card", "border");
        // document.getElementById("card-with-border").checked = true;
        break;
      case false:
        document.documentElement.setAttribute("data-card", "shadow");
        // document.getElementById("card-without-border").checked = true;
        break;
      default:
    }
  },
  //****************************
  // Theme Dark or light
  //****************************
  ManageDarkThemeLayout: function () {
    switch (settings.Theme) {
      case "light":
        setTheme("light", ["light-logo"], ["moon"], ["sun"]);
        break;
      case "dark":
        setTheme("dark", ["dark-logo"], ["sun"], ["moon"]);
        break;
      default:
    }

    function setTheme(theme, hideElements, showElements, hideElements2) {
      document.documentElement.setAttribute("data-bs-theme", theme);
      // document.getElementById(`${theme}-layout`).checked = true;
      hideElements.forEach((el) =>
        document
          .querySelectorAll(`.${el}`)
          .forEach((e) => (e.style.display = "none"))
      );
      showElements.forEach((el) =>
        document
          .querySelectorAll(`.${el}`)
          .forEach((e) => (e.style.display = "flex"))
      );
      hideElements2.forEach((el) =>
        document
          .querySelectorAll(`.${el}`)
          .forEach((e) => (e.style.display = "none"))
      );
    }
  },

  //****************************
  // Theme color
  //****************************
  ManageColorThemeLayout: function () {
    const { ColorTheme } = settings;
    const colorThemeElement = document.getElementById(ColorTheme);

    if (colorThemeElement) {
      document.documentElement.setAttribute("data-color-theme", ColorTheme);
      colorThemeElement.checked = true;
    }
  },
};

// Initialize settings
AdminSettings.AdminSettingsInit();

//****************************
// Handle Click
//****************************

document.addEventListener("turbo:load", function () {
  //****************************
  // Theme Direction RTL LTR click
  //****************************
  function handleDirection() {
    document
      .getElementById("rtl-layout")
      .addEventListener("click", function () {
        document.documentElement.setAttribute("dir", "rtl");
        var offcanvasEnd = document.querySelector(
          ".customizer.offcanvas-end"
        );
        if (offcanvasEnd) {
          offcanvasEnd.classList.toggle("offcanvas-start");
          offcanvasEnd.classList.remove("offcanvas-end");
        }
      });

    document
      .getElementById("ltr-layout")
      .addEventListener("click", function () {
        document.documentElement.setAttribute("dir", "ltr");
        var offcanvasStart = document.querySelector(
          ".customizer.offcanvas-start"
        );
        if (offcanvasStart) {
          offcanvasStart.classList.toggle("offcanvas-end");
          offcanvasStart.classList.remove("offcanvas-start");
        }
      });
  }

  // handleDirection();

  //****************************
  // Theme Layout Box or Full
  //****************************
  function handleBoxedLayout() {
    const boxedLayout = document.getElementById("boxed-layout");
    const fullLayout = document.getElementById("full-layout");
    const containerFluid = document.querySelectorAll(".container-fluid");

    boxedLayout.addEventListener("click", function () {
      containerFluid.forEach(function (element) {
        element.classList.remove("mw-100");
      });
      this.checked;
      document.documentElement.setAttribute("data-boxed-layout", "boxed");
    });

    fullLayout.addEventListener("click", function () {
      containerFluid.forEach(function (element) {
        element.classList.add("mw-100");
      });
      document.documentElement.setAttribute("data-boxed-layout", "full");
      this.checked;
    });
  }
  // handleBoxedLayout();

  //****************************
  // Theme Layout Vertical or horizontal
  //****************************
  function handleLayout() {
    const verticalLayout = document.getElementById("vertical-layout");
    const horizontalLayout = document.getElementById("horizontal-layout");

    verticalLayout.addEventListener("click", function () {
      document.documentElement.setAttribute("data-layout", "vertical");
      this.checked;
    });

    horizontalLayout.addEventListener("click", function () {
      document.documentElement.setAttribute("data-layout", "horizontal");
      this.checked;
    });
  }
  // handleLayout();

  //****************************
  // Theme mode dark or light
  //****************************

  function handleTheme() {
    function setThemeAttributes(
      theme,
      darkDisplay,
      lightDisplay,
      sunDisplay,
      moonDisplay
    ) {
      document.documentElement.setAttribute("data-bs-theme", theme);
      // document.getElementById(`${theme}-layout`).checked = true;

      document
        .querySelectorAll(`.${darkDisplay}`)
        .forEach((el) => (el.style.display = "none"));
      document
        .querySelectorAll(`.${lightDisplay}`)
        .forEach((el) => (el.style.display = "flex"));
      document
        .querySelectorAll(`.${sunDisplay}`)
        .forEach((el) => (el.style.display = "none"));
      document
        .querySelectorAll(`.${moonDisplay}`)
        .forEach((el) => (el.style.display = "flex"));
    }

    document.querySelectorAll(".dark-layout").forEach((element) => {
      element.addEventListener("click", () =>
        setThemeAttributes("dark", "dark-logo", "light-logo", "moon", "sun")
      );
    });

    document.querySelectorAll(".light-layout").forEach((element) => {
      element.addEventListener("click", () =>
        setThemeAttributes("light", "light-logo", "dark-logo", "sun", "moon")
      );
    });
  }
  handleTheme();
  //****************************
  // Theme card with border or shadow
  //****************************
  function handleCardLayout() {
    function setCardAttribute(cardType) {
      document.documentElement.setAttribute("data-card", cardType);
    }

    document
      .getElementById("card-with-border")
      .addEventListener("click", () => setCardAttribute("border"));
    document
      .getElementById("card-without-border")
      .addEventListener("click", () => setCardAttribute("shadow"));
  }
  // handleCardLayout();

  //****************************
  // Theme sidebar
  //****************************
  function handleSidebarToggle() {
    function setSidebarType(sidebarType) {
      document.body.setAttribute("data-sidebartype", sidebarType);
    }

    // document
    //   .getElementById("full-sidebar")
    //   .addEventListener("click", () => setSidebarType("full"));
    // document
    //   .getElementById("mini-sidebar")
    //   .addEventListener("click", () => setSidebarType("mini-sidebar"));
  }
  handleSidebarToggle();
  //****************************
  // Toggle sidebar
  //****************************
  function handleSidebar() {
    document.querySelectorAll(".sidebartoggler").forEach(function (element) {
      element.addEventListener("click", function () {
        document.querySelectorAll(".sidebartoggler").forEach(function (el) {
          el.checked = true;
        });
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
        document.querySelectorAll(".sidebarmenu").forEach(function (el) {
          el.classList.toggle("close");
        });
        var dataTheme = document.body.getAttribute("data-sidebartype");
        if (dataTheme === "full") {
          document.body.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          document.body.setAttribute("data-sidebartype", "full");
        }
      });
    });
  }

  handleSidebar();
});
