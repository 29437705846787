import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("creator registration connected");
    const countrySelect = document.getElementById("user_country_code");
    const citySelect = document.getElementById("city-select");

    if (countrySelect) {
      console.log("country select")
      countrySelect.addEventListener("change", function() {
        const countryCode = countrySelect.value;
  
        if (countryCode) {
          console.log("country code")
          fetch(`/locations/cities?country_code=${countryCode}`)
            .then(response => response.json())
            .then(cities => {
              // Clear previous options
              citySelect.innerHTML = '<option value="">Select your city</option>';
  
              // Populate the city dropdown
              cities.forEach(city => {
                const option = document.createElement('option');
                option.value = city;
                option.textContent = city;
                citySelect.appendChild(option);
              });
            });
        } else {
          citySelect.innerHTML = '<option value="">Select your city</option>';
        }
      });
    }

    $('.tag-input').select2({
      tags: true,
      tokenSeparators: [',', ' ']
    });
  }
  disconnect() {
    console.log("disconnected");
    $('.tag-input').select2('destroy');
  }
}
